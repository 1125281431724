import { Img, Heading } from "../../components";
import React from "react";
import { Pagination, Autoplay, FreeMode, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function AboutUsSection() {
  return (
    <>
      <div className="mt-14 flex flex-col gap-[34px] self-stretch overflow-hidden ss:mt-0 sm:gap-[20px]">
        <div className="container-xs flex flex-col text-center items-center self-center px-14 lg:p-5 md:p-5 md:px-5 sm:px-4">
          <Heading size="textmd" as="h2" className="lg:text-[18px]" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            DIVC is dedicated to fostering<br className="hidden ss:block" /> the growth of the most innovative companies.
          </Heading>
        </div>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={40}
          loop={true}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={3000}
          modules={[Autoplay]}
          className="flex items-center fade-in"
        >
          <SwiperSlide className="flex justify-center p-2.5 w-[auto]">
            <Img src="images/img_rectangle_113_38x130.png" alt="Logo Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113.png" alt="First Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto]">
            <Img src="images/img_rectangle_113_50x140.png" alt="Second Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_1.png" alt="Third Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_2.png" alt="Fourth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_3.png" alt="Fifth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_4.png" alt="Sixth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_5.png" alt="Seventh Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_vector.png" alt="Vector Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_thumbs_up.svg" alt="Thumbs Up" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto]">
            <Img src="images/img_rectangle_113_38x130.png" alt="Logo Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113.png" alt="First Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto]">
            <Img src="images/img_rectangle_113_50x140.png" alt="Second Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_1.png" alt="Third Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_2.png" alt="Fourth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_3.png" alt="Fifth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_4.png" alt="Sixth Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_rectangle_113_5.png" alt="Seventh Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_vector.png" alt="Vector Image" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
          <SwiperSlide className="flex justify-center p-2.5 w-[auto] ">
            <Img src="images/img_thumbs_up.svg" alt="Thumbs Up" className="h-[50px] object-cover sm:h-[35px]" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
