import React from "react";

const sizes = {
  textxs: "text-[16px] font-medium not-italic sm:text-[14px]",
  texts: "text-[16px] font-medium sm:text-[14px]",
  textmd: "text-[18px] font-medium sm:text-[15px]",
  textlg: "text-[20px] font-medium sm:text-[17px]",
  textxl: "text-[24px] font-medium lg:text-[24px] md:text-[22px] sm:text-[20px]",
  text2xl: "text-[32px] font-medium lg:text-[32px] md:text-[30px] sm:text-[27px]",
  text3xl: "text-[80px] font-medium lg:text-[80px] md:text-[48px]",
  headingxs: "text-[18px] font-bold sm:text-[15px]",
  headings: "text-[20px] font-bold sm:text-[17px]",
  headingmd: "text-[22px] font-bold lg:text-[22px] sm:text-[18px]",
  headinglg: "text-[24px] font-bold lg:text-[24px] md:text-[22px] sm:text-[20px]",
  headingxl: "text-[48px] font-bold lg:text-[48px] md:text-[44px] sm:text-[40px]",
};

const Heading = ({ children, className = "", size = "textxs", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-white-a700 font-pretendard ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
