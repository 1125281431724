import { CheckBox, Input, Img, Heading } from "../../components";
import React from "react";

export default function NewsletterSubscriptionSection() {
  return (
    <>
      {/* newsletter subscription section */}
      <div className="self-stretch">
        <div className="flex justify-center bg-gradient2 py-[200px] lg:py-8 md:py-5 sm:py-4 ss:pt-[70px]">
          <div className="container-xs mb-16 flex justify-center px-14 lg:p-5 md:p-5 md:px-5 sm:px-4"
          data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            <div className="flex w-[78%] items-start justify-center lg:w-full md:w-full md:max-w-[570px] md:flex-col md:gap-[70px] ss:gap-[20px]">
              <Heading
                size="text2xl"
                as="h2"
                className="w-[42%] self-center lg:w-[42%] lg:text-[27px] md:w-full md:text-[24px] sm:text-[24px] ss:text-[18px]"
              >
                Subscribe to receive DIVC&#39;s newsletter and get the latest news, updates, and insights
              </Heading>
              <div className="flex flex-1 flex-col items-end gap-5 md:self-stretch md:items-start">
                <Input
                  shape="round"
                  type="email"
                  name="Email Placeholder"
                  placeholder={`Email address`}
                  suffix={<Img src="images/img_arrowleft.svg" alt="Arrow Left" className="h-[38px] w-[38px] cursor-pointer" onClick={() => alert("Coming Soon")} />}
                  className="w-[66%] ss:w-[100%]"
                />
                <CheckBox
                  name="Marketing Consent Checkbox"
                  label="I agree to receive marketing communications from DIVC"
                  id="MarketingConsentCheckbox"
                  className="mr-1 gap-2 text-[14px] text-white-a700 md:mr-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
