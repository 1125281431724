import { Img, Heading } from "./..";
import React from "react";

export default function DiscordRow({ discordText = "Discord", ...props }) {
  return (
    <div {...props} className={`${props.className} flex flex-col gap-3 flex-1`}>
      <div className="flex items-center justify-between gap-5 self-stretch">
        <Heading size="textxl" as="p">
          {discordText}
        </Heading>
        <Img src="images/img_arrow_right.svg" alt="Arrow Image" className="h-[14px]" />
      </div>
      <div className="h-px w-full self-stretch bg-white-a700" />
    </div>
  );
}
