import { Helmet } from "react-helmet";
import { Heading, Img, Button } from "../../components";
import Header from "../../components/Header";
import AboutUsSection from "./AboutUsSection";
import FeaturesOverviewSection from "./FeaturesOverviewSection";
import GlobalEventsSection from "./GlobalEventsSection";
import InfrastructureSection from "./InfrastructureSection";
import NetworkingEventsSection from "./NetworkingEventsSection";
import NewsletterSubscriptionSection from "./NewsletterSubscriptionSection";
import SupportSection from "./SupportSection";
import React, { useEffect, useState } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function New() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX + 250 > windowSize.width ? windowSize.width + 100 : e.clientX + 250,
      y: e.clientY + 150 > windowSize.height ? windowSize.height : e.clientY + 150
    });
  };

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    AOS.init();
  })

  return (
    <>
      <Helmet>
        <title>DIVC Features - High-Speed Blockchain Payments</title>
        <meta
          name="description"
          content="Discover DIVC's patent-pending features for fast, secure blockchain payments. Join our community and participate in events to leverage the full potential of DIVC."
        />
      </Helmet>
      <div className={`flex w-full flex-col items-center bg-gray-900_02 container-mouse`}>
        <div className="gradient-bg-element-1 sm:hidden"
          style={{
            left: mousePosition.x + window.scrollX - 335, // 요소의 절반 너비
            top: mousePosition.y + window.scrollY - 255, // 요소의 절반 높이
          }}
        />

        <div className="flex h-[680px] items-start justify-center self-stretch bg-[url(/public/images/img_main_680x1920.png)] bg-cover bg-no-repeat py-2.5 lg:h-auto md:h-auto">
          <div className="container-xs mb-[142px] flex justify-center lg:p-5 md:p-5" style={{ paddingTop: "0" }}>
            <div className="flex w-full flex-col gap-[210px] lg:gap-[157px] md:gap-[157px] sm:gap-[105px]">
              <Header className="" />
              <div className="flex flex-col items-center text-center md:mx-0">
                <Heading size="text3xl" as="h1" className="lg:text-[60px] md:text-[50px] sm:text-[35px]">
                  Be above<br className="hidden ss:block" /> one&#39;s expectation
                </Heading>
                <Heading size="textlg" as="h2" className="mt-2 sd:text-[17px] ss:mt-5">
                  DIVC offers the advantages of Web3<br className="hidden ss:block" /> with the convenience of Web2
                </Heading>
                <div className="mt-[58px] flex gap-[18px] ss:flex-col">
                  <Button
                    color="lime_400"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_02" onClick={() => alert("Coming Soon")}
                  >
                    Start
                  </Button>
                  <Button
                    color="gray_500"
                    size="md"
                    className="min-w-[252px] rounded-[28px] font-bold !text-gray-900_01" onClick={() => alert("Coming Soon")}
                  >
                    Learn about DIVC
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* about us section */}
        <AboutUsSection />

        {/* infrastructure section */}
        <InfrastructureSection />

        {/* features overview section */}
        <FeaturesOverviewSection />
        <div className="container-xs mt-[200px] flex flex-col items-center px-14 lg:p-5 md:p-5 md:px-5 sm:px-4 sm:mt-[100px]">
          <div className="ml-4 flex w-[92%] flex-col items-center gap-[100px] lg:w-full lg:gap-[100px] md:ml-0 md:w-full md:gap-[75px] sm:gap-[50px]">
            <Heading
              size="textmd"
              as="h2"
              className="text-center !text-[48px] lg:!text-[40px] md:!text-[32px] sm:!text-[24px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
              <>
                Experience the cutting<br className="hidden ss:block" /> edge of <br className="ss:hidden" />
                blockchain technology<br className="hidden ss:block" /> with DIVC
              </>
            </Heading>
            <div className="container-xs flex items-start self-stretch gap-10 md:flex-col md:max-w-[570px]">
              <div className="flex w-[44%] flex-col gap-[26px] self-center md:w-full">
                <div className="flex flex-col items-start gap-[30px] ss:gap-[20px]">
                  <div className="flex flex-col items-start self-stretch">
                    <div className="h-[3px] w-[100%] bg-deep_purple-a200" />
                    <Heading size="texts" as="h3" className="mt-5 !text-[20px] !text-deep_purple-a200 lg:!text-[17px]">
                      Buying and paying for goods
                    </Heading>
                    <Heading size="headingmd" as="h4" className="mt-3 leading-[30px] lg:text-[18px]">
                      <>
                        Buy and pay for goods using the <br className="md:hidden" />
                        DIVC blockchain
                      </>
                    </Heading>
                    <Heading as="h5" className="mt-6 leading-[25px] !text-gray-500 lg:text-[14px]">
                      <>
                        DIVC offers a secure and efficient payment service.
                        <br />
                        Buy and pay for goods directly without intermediaries. <br />
                        With DIVC, all transactions are fast, transparent and secure.
                      </>
                    </Heading>
                  </div>
                  <Img
                    src="images/img_img.png"
                    alt="Feature Image"
                    className="hidden h-[271px] rounded-[50px] object-cover md:w-full md:block "
                  />
                  <div className="h-px w-[100%] bg-gray-500" />
                </div>
                <div className="flex flex-col items-start gap-3">
                  <Heading size="texts" as="h6" className="!text-[20px] !text-deep_purple-a200 lg:!text-[17px]">
                    Hearty events
                  </Heading>
                  <Heading size="headingmd" as="h5" className="leading-[30px] lg:text-[18px]">
                    <>
                      Participate in DIVC blockchain payments <br className="md:hidden" />
                      or events, rewards
                    </>
                  </Heading>
                </div>
                <div className="flex flex-col items-start gap-[18px]">
                  <div className="h-px w-[100%] bg-gray-500" />
                  <Heading size="texts" as="p" className="!text-[20px] !text-deep_purple-a200 lg:!text-[17px]">
                    Other than that service
                  </Heading>
                  <Heading size="headingmd" as="h5" className="lg:text-[18px]">
                    Service in preparation
                  </Heading>
                </div>
              </div>
              <Img
                src="images/img_img.png"
                alt="Feature Image"
                className="h-[472px] w-[56%] rounded-[50px] object-cover md:hidden"
              />
            </div>
          </div>
        </div>

        {/* support section */}
        <SupportSection />

        {/* global events section */}
        <GlobalEventsSection />

        {/* networking events section */}
        <NetworkingEventsSection />

        {/* newsletter subscription section */}
        <NewsletterSubscriptionSection />
        <footer className="flex items-end justify-center self-stretch bg-blue_gray-900_01 py-10 sm:py-4 z-10">
          <div className="container-xs mt-[122px] flex justify-center lg:p-5 md:p-5 sm:mt-[50px] ss:mt-[0]">
            <div className="flex w-full flex-col gap-[110px] lg:gap-[110px] md:gap-[82px] sm:gap-[200px] ss:gap-[150px]">
              <div className="mr-[50px] flex items-start justify-between gap-5 md:mr-0 ">
                <div className="h-[30px] w-[40%] object-contain md:w-[50%]">
                  <Img src="images/img_footer_logo.png" alt="Footer Logo" className="h-[30px] object-contain cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <div className="hidden gap-3 self-center mt-[20px] md:flex sm:mt-[140px] ss:mt-[90px]">
                    <Img src="images/img_user.png" alt="User Image One" className="h-[46px] w-[46px] md:h-[38px] md:w-[38px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                    <Img src="images/twitter.png" alt="User Image Two" className="h-[46px] w-[46px] md:h-[38px] md:w-[38px] object-cove cursor-pointerr" onClick={() => alert("Coming Soon!")} />
                    <Img src="images/youtube.png" alt="User Image Three" className="h-[46px] w-[46px] md:h-[38px] md:w-[38px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                    <Img src="images/instagram.png" alt="User Image Four" className="h-[46px] w-[46px] md:h-[38px] md:w-[38px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  </div>
                </div>
                <div className="flex w-[64%] items-center justify-between gap-5 self-center md:w-full md:grid md:grid-cols-2 sm:hidden">
                  <div className="flex w-[28%] flex-col items-start gap-[22px] md:w-full">
                    <Heading size="headingxs" as="h6" className="lg:text-[15px]">
                      Learn
                    </Heading>
                    <ul className="flex flex-col items-start gap-4">
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Introduction to DIVC
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Move
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Use cases
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Research
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Network status
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Network infomation
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Token schedule
                          </Heading>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex w-[28%] flex-col items-start gap-[22px] md:w-full">
                    <Heading size="headingxs" as="h6" className="lg:text-[15px]">
                      Build
                    </Heading>
                    <ul className="flex flex-col items-start gap-4">
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Developer portal
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Docs
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Courses
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Request for proposal
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Github
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Whitepaper
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Developer forum
                          </Heading>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex w-[28%] flex-col items-start gap-[22px] self-start md:w-full md:self-auto">
                    <Heading size="headingxs" as="h6" className="lg:text-[15px]">
                      Connect
                    </Heading>
                    <ul className="flex flex-col items-start gap-4">
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Event
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Community programs
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Job board
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Directory
                          </Heading>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col items-start gap-[22px]">
                    <Heading size="headingxs" as="h6" className="lg:text-[15px]">
                      About
                    </Heading>
                    <ul className="flex flex-col items-start gap-4">
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            DIVC Foundation
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Careers
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Press center
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Media kit
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Blog
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Trademark Policy
                          </Heading>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Heading as="p" className="!text-[14px] !font-normal">
                            Bug Bounty Program
                          </Heading>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-between gap-5 md:flex-col md:items-end sm:items-start">
                <div className="flex gap-4 self-center md:hidden">
                  <Img src="images/img_user.png" alt="User Image One" className="h-[46px] w-[46px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/twitter.png" alt="User Image Two" className="h-[46px] w-[46px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/youtube.png" alt="User Image Three" className="h-[46px] w-[46px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/instagram.png" alt="User Image Four" className="h-[46px] w-[46px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />

                </div>
                <Heading as="p" className="text-[14px] !font-normal md:text-[12px]">
                  ©2024 COPYRIGHT DIVC Platform. ALL RIGHTS RESERVED.
                </Heading>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
