import { Heading, Button } from "./..";
import React from "react";

export default function EventDetails1({
  inProgressButtonText = "In Progress",
  eventTitle = "Mini Networking in Seoul",
  registerText = "Register",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start w-[24%] md:w-full px-7 py-6 sm:p-4`}>
      <Button color={`${inProgressButtonText == "Closed" ? "gray_500" : "lime_400"}`} shape="round" className="mt-1 min-w-[112px] font-bold cursor-auto">
        {inProgressButtonText}
      </Button>
      <Heading size="headingmd" as="h5" className={`mt-16 ${inProgressButtonText == "Closed" ? "closed" : ""}`}>
        {eventTitle}
      </Heading>
      <Heading size="textmd" as="p" className={`mt-1.5 !font-normal flex items-end gap-2 cursor-pointer ${inProgressButtonText == "Closed" ? "closed" : ""}`} onClick={() => alert("Coming Soon")} >
        {registerText}
        <svg className={`h-[16px] pb-[3px] ${inProgressButtonText == "Closed" ? "closed" : ""}`} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.60968 16L8.06051 14.475L13.4342 9.10136H0V6.87443H13.4342L8.06051 1.52496L9.60968 0L17.6218 7.9879L9.60968 16Z" fill="white" />
        </svg>
      </Heading>
    </div>
  );
}
