import { Heading } from "../../components";
import DiscordRow from "../../components/DiscordRow";
import React, { Suspense } from "react";

const socialLinksList = [{ discordText: "Discord" }, { discordText: "Twitter" }, { discordText: "Facebook" }];

export default function SupportSection() {
  return (
    <>
      {/* support section */}
      <div className="mt-[220px] self-stretch sm:mt-[100px]">
        <div className="flex justify-center bg-blue_gray-900 py-[120px] lg:py-8 md:py-5 sm:py-4 ss:py-10">
          <div className="container-xs mb-[30px] flex justify-center px-14 lg:p-5 md:p-5 md:px-5 ss:px-10">
            <div className="flex w-[90%] items-start justify-between gap-5 lg:w-full md:w-full md:flex-col md:max-w-[570px]">
              <Heading
                size="textmd"
                as="h2"
                className="w-[52%] self-center !text-[48px] lg:w-[52%] lg:!text-[40px] md:w-full md:!text-[32px] sm:!text-[24px]"
                data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
              >
                <>
                  Get the support <br className="md:hidden" />
                  you need, <br />
                  wherever you are.
                </>
              </Heading>
              <div className="ml-[172px] mt-3 flex w-[48%] flex-col gap-3 md:ml-0 md:w-full">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {socialLinksList.map((d, index) => (
                    <DiscordRow {...d} key={"socialLinks" + index} className="lg:gap-3 md:gap-3 cursor-pointer" onClick={() => alert("Coming Soon")}  />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
