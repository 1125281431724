import { Heading, Img, ChipView, Button } from "../../components";
import React from "react";

export default function FeaturesOverviewSection() {
  const [chipOptions, setChipOptions] = React.useState(() => [
    { value: 1, label: `Game` },
    { value: 2, label: `Electronic Commerce` },
    { value: 3, label: `Etc` },
  ]);
  const [selectedChipOptions, setSelectedChipOptions] = React.useState([]);

  return (
    <>
      {/* features overview section */}
      <div className="mt-64 self-stretch sm:mt-[100px]">
        <div className="flex flex-col items-center gap-[98px] lg:gap-[98px] md:gap-[73px] sm:gap-[49px] ss:gap-[20px]">
          <div className="container-xs flex flex-col items-center px-14 lg:p-5 md:p-5 md:px-5 sm:px-4">
            <Heading size="textmd" as="h2" className="!text-[48px] lg:!text-[40px] md:!text-[32px] sm:!text-[24px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
              DIVC’s unique features
            </Heading>
            <Heading size="texts" as="h3" className="mt-7 text-center leading-6 !text-[20px] ss:!text-[14px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
              <>
                Discover DIVC&#39;s unique patent-pending features, <br />
                including rewarding early investors (5% buyers)<br className="hidden ss:block" /> with network fees
              </>
            </Heading>
            <Button size="sm" className="ml-[auto] mr-[auto] mt-[50px] min-w-[208px] self-start rounded-[24px] ss:mt-[40px]" onClick={() => alert("Coming Soon")}
              data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
              Discover DIVC’s features
            </Button>
          </div>
          <div className="relative h-[1090px] content-center self-stretch lg:h-auto md:h-auto">
            <div className="mx-auto flex flex-1 flex-col items-center">
              <div className="container-xs relative z-[4] flex items-start justify-center gap-10 px-14 lg:p-5 md:flex-col md:items-center md:p-5 md:px-5 sm:px-4 md:pb-0">
                <div className="flex w-[100%] max-w-[400px] flex-col items-end"
                  data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                  <Img
                    src="images/img_img1.png"
                    alt="Main Image"
                    className="relative z-[6] h-[200px] w-[100%] rounded-tl-[50px] rounded-tr-[50px] object-cover"
                  />
                  <div className="flex w-[100%] h-[200px] flex-col items-center gap-3.5 rounded-bl-[50px] rounded-br-[50px] bg-blue_gray-900_01 p-[30px] lg:w-full md:w-full sm:p-4">
                    <Heading size="headings" as="h5" className="w-[98%] leading-[26px] lg:w-full  md:w-full">
                      Blockchain-based high-speed payment
                    </Heading>
                    <Heading as="p" className="w-full leading-[25px] ">
                      DIVC provides fast and secure payment services.
                    </Heading>
                  </div>
                </div>
                <div className="flex w-[100%] max-w-[400px] flex-col items-end"
                  data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                  <Img
                    src="images/img_img2.png"
                    alt="Secondary Image"
                    className="h-[200px] w-[100%] rounded-tl-[50px] rounded-tr-[50px] object-cover"
                  />
                  <div className="flex w-[100%] h-[200px] flex-col items-start gap-3 rounded-bl-[50px] rounded-br-[50px] bg-blue_gray-900_01 px-[30px] py-[26px] lg:w-full md:w-full sm:p-4">
                    <Heading size="headings" as="h4" className="">
                      Wow Box
                    </Heading>
                    <Heading size="texts" as="h5" className="w-full leading-[25px] ">
                      If you&#39;re Alice to Bob (an admin or specific event moderator), you&#39;ll be rewarded between
                      50% and 150% of the amount received.
                    </Heading>
                  </div>
                </div>
              </div>
              <div className="relative flex justify-center self-stretch bg-gradient3 py-[50px] md:py-5 sm:py-4">
                <div className="container-xs mb-[198px] flex items-center justify-center gap-10 px-14 lg:p-5 md:flex-col md:p-5 md:px-5 sm:px-4 ss:mb-[20px]">
                  <div className="w-[100%] max-w-[400px]"
                    data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                    <Img
                      src="images/img_img3.png"
                      alt="Product Image"
                      className="h-[200px] w-full rounded-tl-[50px] rounded-tr-[50px] object-cover lg:h-auto md:h-auto"
                    />
                    <div className="flex w-[100%] h-[200px] flex-col items-center justify-center gap-3.5 rounded-bl-[50px] rounded-br-[50px] bg-blue_gray-900_01 p-[26px] sm:p-4">
                      <Heading
                        size="headings"
                        as="h6"
                        className="w-[98%] leading-[26px] lg:w-full  md:w-full"
                      >
                        Trusted product checkout experiences
                      </Heading>
                      <Heading
                        size="texts"
                        as="p"
                        className="w-[98%] leading-[25px] lg:w-full  md:w-full"
                      >
                        We provide low-cost, efficient payments, secure, fast and transparent payment experiences across
                        a wide range of sectors.
                      </Heading>
                    </div>
                  </div>
                  <div className="flex w-[100%] max-w-[400px] h-[400px] flex-col gap-[26px] rounded-[50px] bg-indigo-800 p-[26px] sm:p-4"
                    data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                    <ChipView
                      options={chipOptions}
                      setOptions={setChipOptions}
                      values={selectedChipOptions}
                      setValues={setSelectedChipOptions}
                      className="flex gap-3.5 md:mr-0 ss:flex-wrap"
                    >
                      {(option) => (
                        <React.Fragment key={option.index}>
                          {option.isSelected ? (
                            <div
                              onClick={option.toggle}
                              className="flex h-[42px] min-w-[64px] cursor-pointer flex-row items-center justify-center whitespace-pre-wrap rounded-[20px] bg-lime-400 px-4 text-center text-[15px] font-bold text-black-900_01"
                            >
                              <span>{option.label}</span>
                            </div>
                          ) : (
                            <div
                              onClick={option.toggle}
                              className="flex h-[42px] min-w-[64px] cursor-pointer flex-row items-center justify-center rounded-[20px] bg-gray-500 px-4 text-center text-[15px] font-bold text-black-900_01"
                            >
                              <span>{option.label}</span>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </ChipView>
                    <Heading size="headings" as="h5" className="leading-[30px] ">
                      Manage the ownership and transfer of digital assets more transparently
                    </Heading>
                    <Heading size="texts" as="p" className="leading-[25px] ">
                      A DIVC provides an immutable record of transactions, allowing for more transparent tracking and
                      clarity of ownership.
                    </Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
