import MegaMenu1 from "../MegaMenu1";
import { Button, Img, Heading } from "./..";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Header1({ ...props }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuOpen1, setMenuOpen1] = React.useState(false);
  const [menuOpen2, setMenuOpen2] = React.useState(false);

  return (
    <header {...props} className={`${props.className} flex h-[48px] justify-between items-center gap-5 relative md:p-[20px] `}>
      <Img src="images/img_header_logo.png" alt="Header Logo" className="h-[22px] w-[126px] object-contain cursor-pointer" />
    </header>
  );
}
