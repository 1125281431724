import { Helmet } from "react-helmet";
import { Heading, Img, Button } from "../../components";
import Header1 from "components/Header/index1";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faAndroid, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Page() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowtypeM, setWindowtypeM] = useState(true);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX + 250 > windowSize.width ? windowSize.width + 100 : e.clientX + 250,
      y: e.clientY + 150 > windowSize.height ? windowSize.height : e.clientY + 150
    });
  };

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    AOS.init();
  })

  useEffect(() => {
    const papers = document.querySelectorAll('.paper');
    papers.forEach((paper, index) => {
      setTimeout(() => {
        resetPaper(paper);

        // Re-trigger animation after it ends
        paper.addEventListener('animationiteration', () => {
          resetPaper(paper);
        });
      }, index * 1000); // Delay each paper by 1 second
    });
  }, [windowtypeM]);

  const resetPaper = (paper) => {
    paper.style.left = `${Math.random() * (window.innerWidth - 300)}px`;
    paper.style.animationDuration = `${5 + Math.random() * 3}s`; // Random duration between 5 to 8 seconds
    paper.style.animationName = 'none';
    void paper.offsetHeight;
    paper.style.animationName = 'fall';
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1500) setWindowtypeM(true)
      else setWindowtypeM(false)
    };

    window.addEventListener('resize', handleResize);
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>DIVC Features - High-Speed Blockchain Payments</title>
        <meta
          name="description"
          content="Discover DIVC's patent-pending features for fast, secure blockchain payments. Join our community and participate in events to leverage the full potential of DIVC."
        />
      </Helmet>
      <div className={`flex w-full flex-col items-center bg-gray-900_02 container-mouse`}>
        {/* <div className="gradient-bg-element-1 sm:hidden"
          style={{
            left: mousePosition.x + window.scrollX - 335, // 요소의 절반 너비
            top: mousePosition.y + window.scrollY - 255, // 요소의 절반 높이
          }}
        /> */}

        <div className="flex h-[calc(100vh-80px)] items-start relative justify-center self-stretch bg-[url(/public/images/img_test.png)] bg-cover bg-no-repeat py-2.5 ">
          <div className="container-xs mb-[142px] z-10 flex justify-center lg:p-5 md:p-5" style={{ paddingTop: "0" }}>
            <div className="flex w-full flex-col gap-[210px] lg:gap-[157px] md:gap-[157px] sm:gap-[105px]">
              <Header1 className="" />
              <div className="flex flex-col items-center text-center absolute top-[52%] left-1/2 w-[100%] -translate-x-1/2 -translate-y-[48%] md:mx-0">
                <Heading size="text3xl" as="h1" className="lg:text-[60px] md:text-[50px] sm:text-[35px]">
                  Be above<br className="hidden ss:block" /> one&#39;s expectation
                </Heading>
                <Heading size="textlg" as="h2" className="mt-[30px] sd:text-[17px] ss:mt-5">
                  DIVC offers the advantages of Web3<br className="hidden ss:block" /> with the convenience of Web2
                </Heading>
                <div className="mt-[58px] flex gap-[18px] md:flex-col">
                  <Button
                    color="lime_400"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_02 w-[200px]" onClick={() => window.open("https://scan.divchain.org")}
                  >
                    <FontAwesomeIcon icon={faLink} style={{ paddingRight: "10px" }} /> Block Explorer
                  </Button>
                  <Button
                    color="gray_500"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_02 w-[200px]" onClick={() => window.open("https://market.divchain.org")}
                  >
                    <FontAwesomeIcon icon={faLink} style={{ paddingRight: "10px" }} />NFT Market
                  </Button>
                  <Button
                    color="lime_400"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_01 w-[200px]" onClick={() => window.open("/images/divc-eng-app-release-240801-1113.apk")}
                  >
                    <img src="/images/android.png" style={{ height: "23px", paddingRight: "10px" }} />Android App
                  </Button>
                  <Button
                    color="gray_500"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_02 w-[200px]" onClick={() => window.open("https://divchain.gitbook.io/divc")}
                  >
                    <FontAwesomeIcon icon={faLink} style={{ paddingRight: "10px" }} />White Paper
                  </Button>
                  {/* <Button
                    color="gray_500"
                    size="md"
                    className="min-w-[158px] rounded-[28px] font-bold !text-gray-900_02 w-[200px]" onClick={() => window.open("/images/DIVC (DIV Chain) Yellow Paper_v1.1.pdf")}
                  >
                    <FontAwesomeIcon icon={faFileArrowDown} style={{ fontSize: "19px", paddingRight: "15px" }} />Yellow Paper
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          {
            windowtypeM
              ?
              <div className="paper-container">
                <img src="/images/paper1.png" className="paper" alt="Paper 1" />
                <img src="/images/paper2.png" className="paper" alt="Paper 2" />
                <img src="/images/paper3.png" className="paper" alt="Paper 3" />
                <img src="/images/paper4.png" className="paper" alt="Paper 4" />
                <img src="/images/paper1.png" className="paper" alt="Paper 1" />
              </div>
              :
              ""
          }
        </div>

        <footer className="flex items-end justify-center self-stretch bg-blue_gray-900_01 p-[20px]">
          <div className="container-xs flex justify-center ">
            <div className="flex w-full flex-col gap-[110px] ">
              <div className="flex items-center justify-between gap-5 sm:flex-col">
                <div className="flex gap-4 self-center">
                  <Img src="images/img_user.png" alt="User Image One" className="h-[40px] w-[40px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/twitter.png" alt="User Image Two" className="h-[40px] w-[40px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/youtube.png" alt="User Image Three" className="h-[40px] w-[40px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                  <Img src="images/instagram.png" alt="User Image Four" className="h-[40px] w-[40px] object-cover cursor-pointer" onClick={() => alert("Coming Soon!")} />
                </div>
                <Heading as="p" className="text-[14px] !font-normal md:text-[12px] sm:text-[10px]">
                  ©2024 COPYRIGHT DIVC PLATFORM. ALL RIGHTS RESERVED.
                </Heading>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
