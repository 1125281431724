import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Page from "pages/Page";
import New from "pages/New"

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Page /> },
    { path: "/new", element: <New /> },
    { path: "*", element: <NotFound /> }
  ]);

  return element;
};

export default ProjectRoutes;
