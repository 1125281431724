import { Heading } from "../../components";
import EventDetails1 from "../../components/EventDetails1";
import React, { Suspense } from "react";
import '../../styles/styles.css'

const eventStatusList = [
  { inProgressButtonText: "In Progress", eventTitle: "Mini Networking in Seoul", registerText: "Register" },
  { inProgressButtonText: "In Progress", eventTitle: "DIVC Consortium 2024", registerText: "Register" },
  { inProgressButtonText: "Closed", eventTitle: "DIVC Meetup in Seoul", registerText: "More" },
  { inProgressButtonText: "Closed", eventTitle: "Global Networking Event", registerText: "More" },
];

export default function NetworkingEventsSection() {
  return (
    <>
      {/* networking events section */}
      <div className="mt-[200px] h-[700px] self-stretch bg-[url(/public/images/img_group_184.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto sm:mt-[50px]">
        <div className="flex flex-col h-[100%] items-center justify-end bg-black-900_99 py-20 lg:py-8 md:py-5 sm:py-4">
          <div className="container-xs mt-[50px] flex flex-col items-center gap-[100px] lg:gap-[100px] lg:p-5 md:gap-[75px] md:p-5 sm:gap-[50px]">
            <div className="flex w-[40%] flex-col items-center gap-8 lg:w-full md:w-full">
              <Heading size="textmd" as="h2" className="!text-[48px] lg:!text-[40px] md:!text-[32px] sm:!text-[24px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                Join DIVC Builder
              </Heading>
              <Heading size="texts" as="h3" className="text-center !text-[20px] ss:!text-[14px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                <>
                  DIVC community events provide opportunities
                  <br />
                  to network and share ideas.
                </>
              </Heading>
            </div>
            <div className="flex gap-3 self-stretch md:flex-col md:grid md:grid-cols-2 ss:grid-cols-1"
            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
              <Suspense fallback={<div>Loading feed...</div>}>
                {eventStatusList.map((d, index) => (
                  <EventDetails1 {...d} key={"listColumn" + index} className={`${d.inProgressButtonText == "Closed" ? "bg-white-a700" : "bg-deep_purple-a200"} ss:h-[225px] ss:p-6`} />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
