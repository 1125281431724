import { Heading, Img, Slider } from "../../components";
import React from "react"

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';;

export default function InfrastructureSection() {
  return (
    <>
      {/* infrastructure section */}
      <div className="mt-[200px] flex flex-col items-center relative self-stretch sm:mt-[50px]">
        <div className="container-xs flex flex-col items-center gap-[60px] px-3.5 lg:p-5 md:p-5 sm:gap-[30px]">
          <div className="flex flex-col gap-[100px] self-stretch lg:gap-[100px] md:gap-[75px] sm:gap-[50px]">
            <div className="flex flex-col items-center gap-[30px] md:mx-0 ss:gap-[20px]">
              <Heading
                size="textmd"
                as="h2"
                className="text-center !text-[48px] lg:!text-[40px] md:!text-[32px] sm:!text-[24px]"
                data-aos="fade-up" data-aos-duration="1000" data-aos-once="true"
              >
                <>
                  A scalable infrastructure
                  <br />
                  that is fast, secure,<br className="hidden ss:block" /> and cost-effective.
                </>
              </Heading>
              <Heading size="texts" as="h3" className="text-center text-[20px] leading-6 ss:text-[14px]"
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                <>
                  Enjoy unparalleled speed and low fees. <br />
                  Costs are reduced as demand increases.
                </>
              </Heading>
            </div>
            <div className="relative h-[630px] ss:h-[500px]">
              <div className="absolute bottom-0 left-0 right-0 top-0 m-auto w-full max-w-[1370px]">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={20}
                  loop={true}
                  slidesPerView={1}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    700: {
                      slidesPerView: 1.2,
                    }
                  }}
                  className="swiper"
                  data-aos="zoom-in" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                >
                  <SwiperSlide>
                    <div className="flex items-center md:flex-col h-[650px] sm:h-[500px]">
                      <div className="flex flex-1 flex-col items-start gap-8 rounded-[50px] border-2 border-solid border-gray-500 bg-gray-900_03 py-14 pl-[60px] pr-14 md:self-stretch md:p-5 sm:p-4 ss:gap-4">
                        <Heading
                          size="headingxl"
                          as="h4"
                          className="mt-[18px] !text-lime-400 lg:text-[40px] md:text-[32px] sm:text-[24px]"
                        >
                          Stable and rapid transactions
                        </Heading>
                        <Heading size="texts" as="h5" className="w-full !text-[20px] leading-6 lg:!text-[17px] ss:!text-[14px] xs:!text-[13px]">
                          <>
                            We provide stable processing speeds and exceptional performance, with a benchmark of{" "}
                            <br />
                            1000 transactions per second (TPS)Enjoy effortless transactions with unparalleled speed
                            and reliability.
                          </>
                        </Heading>
                        <img src="/images/img1.png" className="w-[95%] h-[300px] sm:hidden" />
                        <img src="/images/img4.png" className="w-[100%] h-[300px] sm:h-[300px] hidden sm:block xs:h-[200px] xx:h-[150px]" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center md:flex-col h-[650px] sm:h-[500px]">
                      <div className="flex flex-1 flex-col items-start gap-8 rounded-[50px] border-2 border-solid border-gray-500 bg-gray-900_03 py-14 pl-[60px] pr-14 md:self-stretch md:p-5 sm:p-4 ss:gap-4">
                        <Heading
                          size="headingxl"
                          as="h4"
                          className="mt-[18px] !text-lime-400 lg:text-[40px] md:text-[32px] sm:text-[24px]"
                        >
                          High speed transaction processing.
                        </Heading>
                        <Heading size="texts" as="h5" className="w-full !text-[20px] leading-6 lg:!text-[17px] ss:!text-[14px] xs:!text-[13px]">
                          <>
                            Maximise efficiency with cutting-edge technology that executes transactions in seconds, ensuring fast
                            transaction processing even as the number of users on your network grows.
                          </>
                        </Heading>
                        <div className="w-[100%] h-[300px] md:h-[300px] xs:h-[200px] xx:h-[150px]">
                          <img src="/images/img2.png" className="w-[95%] h-[95%] mb-3 sm:hidden" />
                          <img src="/images/img5.png" className="w-[100%] h-[100%] hidden sm:block" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center md:flex-col h-[650px] sm:h-[500px]">
                      <div className="flex flex-1 flex-col items-start gap-8 rounded-[50px] border-2 border-solid border-gray-500 bg-gray-900_03 py-14 pl-[60px] pr-14 md:self-stretch md:p-5 sm:p-4 ss:gap-4">
                        <Heading
                          size="headingxl"
                          as="h4"
                          className="mt-[18px] !text-lime-400 lg:text-[40px] md:text-[32px] sm:text-[24px]"
                        >
                          Fast and Cost-Effective Structure
                        </Heading>
                        <Heading size="texts" as="h5" className="w-full !text-[20px] leading-6 lg:!text-[17px] ss:!text-[14px] xs:!text-[13px]">
                          <>
                            Our innovative fee structure minimises the burden on users. <br />
                            Enjoy efficient trading without high costs with an affordable structure designed to save you money.{" "}
                          </>
                        </Heading>
                        <div className="self-stretch md:h-[300px] xs:h-[200px] xx:h-[150px]">
                          <div className="flex justify-center">
                            <div className="flex w-full items-center justify-start gap-7">
                              <img src="/images/img3.png" className="w-[100%] h-[300px] sm:hidden" />
                              <img src="/images/img6.png" className="w-[100%] h-[100%] hidden sm:block" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 z-[3] h-[650px] w-[20%] bg-gradient-r sm:hidden" />
      </div>
    </>
  );
}
