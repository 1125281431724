import MegaMenu1 from "../MegaMenu1";
import { Button, Img, Heading } from "./..";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Header({ ...props }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuOpen1, setMenuOpen1] = React.useState(false);
  const [menuOpen2, setMenuOpen2] = React.useState(false);

  return (
    <header {...props} className={`${props.className} flex justify-between items-center gap-5 relative md:p-[20px] `}>
      <Img src="images/img_header_logo.png" alt="Header Logo" className="h-[22px] w-[126px] object-contain cursor-pointer" onClick={() => alert("Coming Soon")} />
      <ul className="flex gap-7 md:hidden">
        <li
          onMouseLeave={() => {
            setMenuOpen(false);
          }}
          onMouseEnter={() => {
            setMenuOpen(true);
          }}
        >
          <div className="flex cursor-pointer gap-2.5 p-2">
            <Heading size="textmd" as="p">
              Learn
            </Heading>
            <Img src="images/img_close.svg" alt="Close Icon" className="h-[24px] w-[24px]" />
          </div>
          {menuOpen ? <MegaMenu1 /> : null}
        </li>
        <li
          onMouseLeave={() => {
            setMenuOpen1(false);
          }}
          onMouseEnter={() => {
            setMenuOpen1(true);
          }}
        >
          <div className="flex cursor-pointer gap-2.5 p-2">
            <Heading size="textmd" as="p">
              Build
            </Heading>
            <Img src="images/img_close.svg" alt="Close Icon" className="h-[24px] w-[24px]" />
          </div>
          {menuOpen1 ? <MegaMenu1 /> : null}
        </li>
        <li
          onMouseLeave={() => {
            setMenuOpen2(false);
          }}
          onMouseEnter={() => {
            setMenuOpen2(true);
          }}
        >
          <div className="flex cursor-pointer gap-2.5 p-2">
            <Heading size="textmd" as="p">
              Connect
            </Heading>
            <Img src="images/img_close.svg" alt="Close Icon" className="h-[24px] w-[24px]" />
          </div>
          {menuOpen2 ? <MegaMenu1 /> : null}
        </li>
      </ul>
      <Button size="sm" className="min-w-[108px] rounded-[24px] md:hidden" onClick={() => alert("Coming Soon")} >
        Start DIVC
      </Button>
      <FontAwesomeIcon icon={faBars} fontSize={22} color="white" className="hidden md:block" />
    </header>
  );
}
