import { Img, Heading, Slider, Button } from "../../components";
import React, { useEffect, useRef } from "react";

import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';;

export default function GlobalEventsSection() {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  return (
    <>
      {/* global events section */}
      <div className="mt-[206px] flex items-center justify-center gap-[38px] self-stretch overflow-hidden md:flex-col sm:mt-[100px]">
        <div className="flex w-[92%] justify-center md:w-full md:p-5">
          <div className="flex w-full flex-col items-end">
            <div className="container-xs flex flex-col items-center gap-[46px] px-14 lg:p-5 md:p-5 md:px-5 sm:px-4 ss:gap-[30px]">
              <Heading size="textmd" as="h2" className="text-center !text-[48px] lg:!text-[40px] md:!text-[32px] sm:!text-[24px]"
              data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                We are together in<br className="hidden ss:block" /> 198 countries.
              </Heading>
              <div className="flex px-7 sm:px-4">
                <Button size="sm" className="min-w-[140px] rounded-[24px]" onClick={() => alert("Coming Soon")} 
                  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                  View All Events
                </Button>
              </div>
            </div>
            <div className="mt-[100px] relative flex justify-center gap-10 self-stretch md:flex-col sm:mt-[30px]">
              <div className="container-xs mx-auto flex w-full max-w-[1400px] lg:p-5 md:p-5 ss:p-1">
                <Swiper
                  ref={swiperRef}
                  modules={[Pagination, Autoplay]}
                  spaceBetween={5}
                  loop={true}
                  slidesPerView={1.1}
                  centeredSlides={true}
                  // autoplay={{
                  //   delay: 5000,
                  //   disableOnInteraction: false,
                  // }}
                  // speed={3000}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    768: {
                      spaceBetween: 20,
                      slidesPerView: 1.2,
                    }
                  }}
                  className="swiper"
                  data-aos="zoom-in" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                >
                  <SwiperSlide>
                    <div className="flex items-start gap-[30px] rounded-[50px] border-2 border-solid border-white-a700 md:flex-col">
                      <Img
                        src="images/img_rectangle_22.png"
                        alt="Event Image"
                        className="h-[460px] w-[75%] self-center rounded-bl-[50px] rounded-tl-[50px] object-cover md:w-full md:hidden"
                      />
                      <div className="mt-[50px] flex w-[25%] flex-col items-start gap-[88px] lg:gap-[88px] md:w-full md:gap-[66px] md:p-[50px] md:mt-[0px] sm:gap-11 ss:p-[30px]">
                        <Heading size="headinglg" as="h5" className="leading-10 lg:text-[20px]">
                          <>
                            July 1st, 2024
                            <br />
                            Seoul
                          </>
                        </Heading>
                        <Heading size="texts" as="h6" className="!text-[20px] leading-8 lg:!text-[17px]">
                          <>
                            July Offline Event
                            <br />
                            <br />
                            Join the event and gain
                            <br />a wealth of information
                          </>
                        </Heading>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-start gap-[30px] rounded-[50px] border-2 border-solid border-white-a700 md:flex-col">
                      <Img
                        src="images/img_rectangle_22.png"
                        alt="Event Image"
                        className="h-[460px] w-[75%] self-center rounded-bl-[50px] rounded-tl-[50px] object-cover md:w-full md:hidden"
                      />
                      <div className="mt-[50px] flex w-[25%] flex-col items-start gap-[88px] lg:gap-[88px] md:w-full md:gap-[66px] md:p-[50px] md:mt-[0px] sm:gap-11 ss:p-[30px]">
                        <Heading size="headinglg" as="h5" className="leading-10 lg:text-[20px]">
                          <>
                            July 1st, 2024
                            <br />
                            Seoul
                          </>
                        </Heading>
                        <Heading size="texts" as="h6" className="!text-[20px] leading-8 lg:!text-[17px]">
                          <>
                            July Offline Event
                            <br />
                            <br />
                            Join the event and gain
                            <br />a wealth of information
                          </>
                        </Heading>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-start gap-[30px] rounded-[50px] border-2 border-solid border-white-a700 md:flex-col">
                      <Img
                        src="images/img_rectangle_22.png"
                        alt="Event Image"
                        className="h-[460px] w-[75%] self-center rounded-bl-[50px] rounded-tl-[50px] object-cover md:w-full md:hidden"
                      />
                      <div className="mt-[50px] flex w-[25%] flex-col items-start gap-[88px] lg:gap-[88px] md:w-full md:gap-[66px] md:p-[50px] md:mt-[0px] sm:gap-11 ss:p-[30px]">
                        <Heading size="headinglg" as="h5" className="leading-10 lg:text-[20px]">
                          <>
                            July 1st, 2024
                            <br />
                            Seoul
                          </>
                        </Heading>
                        <Heading size="texts" as="h6" className="!text-[20px] leading-8 lg:!text-[17px]">
                          <>
                            July Offline Event
                            <br />
                            <br />
                            Join the event and gain
                            <br />a wealth of information
                          </>
                        </Heading>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-start gap-[30px] rounded-[50px] border-2 border-solid border-white-a700 md:flex-col">
                      <Img
                        src="images/img_rectangle_22.png"
                        alt="Event Image"
                        className="h-[460px] w-[75%] self-center rounded-bl-[50px] rounded-tl-[50px] object-cover md:w-full md:hidden"
                      />
                      <div className="mt-[50px] flex w-[25%] flex-col items-start gap-[88px] lg:gap-[88px] md:w-full md:gap-[66px] md:p-[50px] md:mt-[0px] sm:gap-11 ss:p-[30px]">
                        <Heading size="headinglg" as="h5" className="leading-10 lg:text-[20px]">
                          <>
                            July 1st, 2024
                            <br />
                            Seoul
                          </>
                        </Heading>
                        <Heading size="texts" as="h6" className="!text-[20px] leading-8 lg:!text-[17px]">
                          <>
                            July Offline Event
                            <br />
                            <br />
                            Join the event and gain
                            <br />a wealth of information
                          </>
                        </Heading>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-start gap-[30px] rounded-[50px] border-2 border-solid border-white-a700 md:flex-col">
                      <Img
                        src="images/img_rectangle_22.png"
                        alt="Event Image"
                        className="h-[460px] w-[75%] self-center rounded-bl-[50px] rounded-tl-[50px] object-cover md:w-full md:hidden"
                      />
                      <div className="mt-[50px] flex w-[25%] flex-col items-start gap-[88px] lg:gap-[88px] md:w-full md:gap-[66px] md:p-[50px] md:mt-[0px] sm:gap-11 ss:p-[30px]">
                        <Heading size="headinglg" as="h5" className="leading-10 lg:text-[20px]">
                          <>
                            July 1st, 2024
                            <br />
                            Seoul
                          </>
                        </Heading>
                        <Heading size="texts" as="h6" className="!text-[20px] leading-8 lg:!text-[17px]">
                          <>
                            July Offline Event
                            <br />
                            <br />
                            Join the event and gain
                            <br />a wealth of information
                          </>
                        </Heading>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="absolute bottom-0 right-0 top-0 z-[3] my-auto h-[600px] w-[20%] bg-gradient-r sm:hidden" />
              <div className="absolute bottom-0 left-0 top-0 z-[3] my-auto h-[600px] w-[20%] bg-gradient-l sm:hidden" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
